<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Aprovar Despesas</h1>
    <ExpenditureForm
      :inApprove="true"
      :show="approve.show"
      :expenditureId="approve.expenditureId"
      :userId="approve.userId"
      v-on:cancel="closeForm"
      v-on:expenditureApproved="expenditureApproved"
    />
    <div style="height: 65vh">
      <DataTable
        :value="expenditureList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Não existem despesas. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div class="table-header">
            <label for="year-selector" class="p-mr-1">Ano</label>
            <InputNumber
              id="year-selector"
              name="year-selector"
              :value="selectedYear"
              inputStyle="width: 65px"
              showButtons
              buttonLayout="stacked"
              :step="1"
              :min="currentYear - 10"
              :max="currentYear"
              :useGrouping="false"
              @input="yearChanged"
            />
          </div>
        </template>
        <Column field="user" header="Funcionário" sortable filterField="user">
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter p-col-12"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="date"
          header="Data"
          filterField="date"
          dataType="date"
          sortable
        >
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
          <template #body="slotProps">
            <span class="p-column-title">Data:</span>
            {{ slotProps.data.day }}
          </template>
        </Column>
        <Column
          field="businessName"
          header="Negócio"
          sortable
          filterField="businessName"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="installationName"
          header="Instalação"
          sortable
          filterField="installationName"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column field="patName" header="Pat" filterField="patName" sortable>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="value"
          header="Valor"
          filterField="value"
          dataType="numeric"
          sortable
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
          <template #body="slotProps">
            <span class="p-column-title">Valor:</span>
            {{ slotProps.data.value.toFixed(2) }} €
          </template>
        </Column>
        <Column
          field="rejectReason"
          header="Razão Rejeição"
          sortable
          filterField="rejectReason"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          :headerStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :filterHeaderStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :bodyStyle="{ 'min-width': '5%', 'max-width': '5%' }"
        >
          <template #body="slotProps">
            <Button
              icon="pi pi-eye"
              class="p-button-rounded p-button-text p-button-success"
              @click="approveExpenditure(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import expenditureService from "../services/expenditure.service";
import ExpenditureForm from "../components/expenditure/ExpenditureForm.vue";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "ExpenditureApprove",
  components: {
    ExpenditureForm,
  },
  data() {
    return {
      loading: true,
      expenditureList: [],
      filters: {
        date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        businessName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        installationName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        patName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        value: { value: null, matchMode: FilterMatchMode.EQUALS },
        user: { value: null, matchMode: FilterMatchMode.CONTAINS },
        rejectReason: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      selectedYear: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      approve: { show: false, expenditureId: null, userId: null },
    };
  },
  async created() {
    this.loading = true;
    this.expenditureList = await this.getExpenditureNotApproved();
    this.loading = false;
  },
  methods: {
    async yearChanged(year) {
      if (this.selectedYear == year) {
        return;
      }
      this.selectedYear = year;
      this.expenditureList = await this.getExpenditureNotApproved();
    },
    getExpenditureNotApproved() {
      this.loading = true;
      return expenditureService
        .getExpenditureNotAproved(this.selectedYear)
        .then((response) => {
          response = response.map((v) => ({ ...v, date: new Date(v.day) }));
          this.loading = false;
          return response;
        });
    },
    approveExpenditure(expenditure) {
      return (this.approve = {
        show: true,
        expenditureId: expenditure.id,
        userId: expenditure.userId,
      });
    },
    closeForm() {
      return (this.approve = {
        show: false,
        expenditureId: null,
        userId: null,
      });
    },
    expenditureApproved(info) {
      const areYou = (expenditureAux) => {
        return expenditureAux.id == this.approve.expenditureId;
      };
      let index = this.expenditureList.findIndex(areYou);
      if (info.approved == 0) {
        this.expenditureList[index].rejectReason = info.reason;
      } else if (info.approved == 1) {
        this.expenditureList.splice(index, 1);
      }
      return this.closeForm();
    },
  },
};
</script>
<style scoped lang="scss">
#year-selector {
  width: 60px;
}

.image-preview {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  max-width: 100% !important;
  max-height: 100% !important;
  height: 10vw;
  width: auto;
}

.image-view {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  max-width: 100% !important;
  max-height: 100% !important;
  height: 75vh !important;
  width: auto !important;
}

.pdf-viewer {
  text-align: center;
}
</style>
